//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { changeServer } from '@/electron'
import { getDefaultServer } from '@/utils'
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    bufferedServer: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      server: this.bufferedServer,
      defaultServer: getDefaultServer(),
      loading: false,
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    setError (message) {
      if (message) {
        this.error = true
        this.errorMessage = message
      } else {
        this.error = false
        this.errorMessage = ''
      }
    },
    clearError () {
      this.setError()
    },
    async checkServerExists (server) {
      // name for a globally available fn, that JSONP will call
      const globalCheckerFnName = 'checkServerExists'

      // define this fn
      // JSONP will call in with it with contents of 'features.json'
      window[globalCheckerFnName] = features => {
        if (features) {
          const buffer = { server }
          changeServer(buffer)
        } else {
          // should never get to this point
          // (see 'script.onerror' that will fire first)
          this.setError(this.$t('auth.newServerError'))
        }
        this.loading = false
      }

      // construct URL to retrive JSONP with
      const fileURL = server + `/features.js?jsonp=${globalCheckerFnName}`

      // create script element with this URL
      const script = document.createElement('script')
      script.src = fileURL

      // provide 'onerror' callback that will fire,
      // if features JSONP does not exist on server
      script.onerror = () => {
        this.setError(this.$t('auth.newServerError'))
        this.loading = false
      }

      // append the script to head, where it will immediately start loading
      document.querySelector('head').appendChild(script)
    },
    async tryServerChange () {
      this.loading = true
      this.clearError()
      let newServer = this.defaultServer
      if (this.server) {
        const prefix = (this.server.startsWith('http:') || this.server.startsWith('https:')) ? '' : 'https://'
        newServer = prefix + this.server
      }
      if (newServer.startsWith(location.origin)) {
        this.setError(this.$t('auth.sameServerError'))
      } else {
        this.checkServerExists(newServer)
      }
    },
  },
}
